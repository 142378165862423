import { Device } from '@components/Layout';
import IconMinusCircle from '@svg-icons/IconMinusCircle';
import IconPlusCircle from '@svg-icons/IconPlusCircle';
import styles from './css';

type Props = {
  icon?: JSX.Element;
  label: string;
  maxNumber?: number;
  minNumber?: number;
  onClickOfDecrement?: () => void;
  onClickOfIncrement?: () => void;
  setNoOfGuest: (argument: number) => void;
  value: number;
};

const InputStepper = ({
  icon,
  label,
  maxNumber,
  minNumber,
  onClickOfDecrement,
  onClickOfIncrement,
  setNoOfGuest,
  value,
}: Props) => {
  const increment: React.ComponentProps<'button'> = {
    disabled: value === maxNumber,
    onClick: () => {
      setNoOfGuest(value + 1);
      onClickOfIncrement?.();
    },
  };

  const decrement: React.ComponentProps<'button'> = {
    disabled: value === minNumber || value === 0,
    onClick: () => {
      setNoOfGuest(value - 1);
      onClickOfDecrement?.();
    },
  };

  return (
    <div className="input-stepper">
      <Device mobile={true} tablet={true}>
        <div className="input-stepper__container">
          {icon && <div className="input-stepper-icon">{icon}</div>}
          <span className="input-stepper-value">{value}</span>
          <div>{label}</div>
        </div>
        <div>
          <button {...decrement} type="button">
            <IconMinusCircle />
          </button>
          <button {...increment} type="button">
            <IconPlusCircle />
          </button>
        </div>
      </Device>
      <Device desktop={true}>
        <div>{label}</div>
        <div>
          <button {...decrement} type="button">
            <IconMinusCircle />
          </button>
          <span className="input-stepper-value">{value}</span>
          <button {...increment} type="button">
            <IconPlusCircle />
          </button>
        </div>
      </Device>
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default InputStepper;
